// CastButton component
import React, { useCallback } from "react";
import { useCast } from "react-chromecast";

function ChromiumWarning() {
  // There's no definite way to detect chrome/chromium. This may break anytime
  var isChromium = !!window.chrome || navigator.userAgent.includes("Chrome");
  console.log(`isChromium :${isChromium}`);
  if (isChromium) {
    return null;
  } else {
    return (
      <p>
        Run this app only in Chromium based browsers such as Google Chrome or
        Edge.
      </p>
    );
  }
}

function App() {
  const cast = useCast({
    initialize_media_player: "DEFAULT_MEDIA_RECEIVER_APP_ID",
    auto_initialize: true,
  });
  const handleClick = useCallback(async () => {
    if (cast.castReceiver) {
      await cast.handleConnection();
    }
  }, [cast.castReceiver, cast.handleConnection]);
  return (
    <>
      <ChromiumWarning />
      <button onClick={handleClick}>castIcon</button>
    </>
  );
}
export default App;
